import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';

class SpUseStepSection extends React.Component {
    render() {
        return (
            <Wrapper id="use_step">
                <Widget.MaxWidth
                    width={'1024'}
                    extraCss={`
                        padding: 90px 30px;
                        @media screen and (max-width: 600px) {
                            padding: 50px 20px;
                        }
                    `}
                >
                    <Title
                      extraCss="
                      margin-bottom: 100px;
                      @media screen and (max-width: 600px) {
                          margin-bottom: 80px;
                      }
                      ">
                      使用步驟
                    </Title>

                    <Widget.Row justify='center' wrap={'true'}>
                      <StepContainer>
                        <div>
                          <div className="line" />
                          <div className="ball">
                            1
                          </div>
                        </div>
                        <p>
                          「設定」開啟<span>行動數據</span>和<span>數據漫遊</span>，即可連網
                        </p>
                      </StepContainer>
                      <StepContainer>
                        <div>
                          <div className="line" />
                          <div className="ball">
                            2
                          </div>
                        </div>
                        <p>
                          完成設定後<span>重新開機</span>套用您的設定
                        </p>
                      </StepContainer>
                    </Widget.Row>
                </Widget.MaxWidth>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    background-image: linear-gradient(180deg, #2b2b2b, #2b2b2b);
`;

const Title = styled.div`
    text-align: center;
    font-size: 50px;
    font-weight: 400;
    color: #dbdbdb;

    @media screen and (max-width: 1024px){
        font-size: 33px;
    }

    @media screen and (max-width: 600px){
        font-size: 20px;
    }

    ${props => props.extraCss};
`;

  const StepContainer = styled.div`
    width: 250px;
    @media screen and (max-width: 539px){
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 180px;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      margin-bottom: 30px;
      position: relative;
      @media screen and (max-width: 539px){
        flex-direction: column;
        width: 50px;
        height: 180px;
        margin-bottom: 0px;
        margin-right: 30px;
      }

      & > .line {
        background-color: #979797;
        width: 100%;
        height: 1px;
        @media screen and (max-width: 539px){
          width: 1px;
          height: 180px;
        }
      }

      & > .ball {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background-color: #1ea55c;
        position: absolute;
        font-size: 20px;
        color: #fff;
        @media screen and (min-width: 540px){
          bottom: 0;
        }
        @media screen and (max-width: 539px){
          right: 0;
        }
      }
    }

    & > p {
      text-align: center;
      font-weight: 200;
      font-size: 18px;
      color: #bebebe;
      padding: 0 20px;
      @media screen and (max-width: 539px){
        width: 150px;
        padding: 0;
      }

      & > span {
        font-weight: 200;
        font-size: 18px;
        color: #009eda;
      }
    }
  `;

export default SpUseStepSection;
