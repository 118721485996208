import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';

const troubleshootings = [{
  id: 1,
  body: (
    <div className="text">
      <span className="highlight">確認APN </span><div>&nbsp;</div><span className="highlight">IOS:</span> 於設定-> 行動網路-> 將「行動數據APN」改為<span className="highlight">mobile.power3g</span><div>&nbsp;</div><span className="highlight"> ANDROID:</span> 設定-> 行動網路-> 存取點名稱-> 新增1010並於APN欄位輸入:<span className="highlight">mobile.power3g</span>
    </div>
  ),
}, {
  id: 2,
  body: (
    <div className="text">
      <span className="highlight">雙卡機</span><div>&nbsp;</div>網卡請插<span className="highlight">SIM1</span>卡槽，並確認網路選擇為<span className="highlight">SIM1</span>。
    </div>
  ),
}, {
  id: 3,
  body: (
    <div className="text">
      <span className="highlight">輸入PUK，無法取用您的SIM。 </span><div>&nbsp;</div>請輸入您手機本身對<span className="highlight">SIM卡</span>設定的密碼(通常為4位數)。
    </div>
  ),
}, {
  id: 4,
  body: (
    <div className="text">
      <span className="highlight">無SIM卡</span><div>&nbsp;</div>請簡易的擦拭<span className="highlight">SIM卡</span>後再重新插入。
    </div>
  ),
}]

class SpTroubleshootingSection extends React.Component {
    render() {
        return (
            <Wrapper id="use_step">
                <Widget.MaxWidth
                    width={'1024'}
                    extraCss={`
                        padding: 90px 30px;
                        @media screen and (max-width: 600px) {
                            padding: 50px 20px;
                        }
                    `}
                >
                    <Title extraCss="margin-bottom: 100px;">疑難排解</Title>

                    <Widget.Row justify='center' wrap={'true'}>
                      {troubleshootings.map((troubleshooting, index) => (
                        <StepContainer key={troubleshooting.id} extraCss='margin-right: 10px; margin-bottom: 60px;'>
                            <StepBlock><div className="step-number">{index + 1}</div></StepBlock>
                            <DescriptionBlock>
                                {troubleshooting.body}
                            </DescriptionBlock>
                        </StepContainer>
                      ))}
                    </Widget.Row>
                </Widget.MaxWidth>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    background-image: linear-gradient(180deg, #3b3b3b, #3b3b3b);
`;

const Title = styled.div`
    text-align: center;
    font-size: 50px;
    font-weight: 400;
    color: #dbdbdb;

    @media screen and (max-width: 1024px){
        font-size: 33px;
    }

    @media screen and (max-width: 600px){
        font-size: 20px;
    }

    ${props => props.extraCss};
`;

const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    ${props => props.extraCss};
`;

const StepBlock = styled.div`
    position: absolute;
    top: -25px;
    transform: rotate(45deg);
    background-color: #1ea55c;
    width: 50px;
    height: 50px;

    & > .step-number {
        transform: rotate(-45deg);
        line-height: 50px;
        text-align: center;
        font-size: 33px;
        color: #dbdbdb;
    }
`;

const DescriptionBlock = styled.div`
    padding: 50px 20px 15px;
    background-color: #5c5959;
    width: 230px;
    min-height: 335px;

    & > .text {
        text-align: center;
        font-weight: 200;
        font-size: 18px;
        color: #bebebe;
    }

    & .highlight {
        font-weight: 200;
        font-size: 18px;
        color: #009eda;
    }
`;

export default SpTroubleshootingSection;
