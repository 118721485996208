import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';
import * as Icon from './Icon';

class SpFaqItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAnswer: false,
        }
    }

    render() {
        let { question, answer } = this.props;
        let { showAnswer } = this.state;

        return (
            <Wrapper>
                <Widget.Row
                    justify='space-between'
                    align='center'
                    extraCss='margin-bottom: 15px; cursor: pointer'
                    onClick={() => this.setState({ showAnswer: !showAnswer })}
                >
                    <QuestionText click={showAnswer}>{question}</QuestionText>
                    {
                        showAnswer
                        ? <Icon.KeyboardArrowUp color='#009eda' />
                        : <Icon.KeyboardArrowDown color='#dbdbdb' />
                    }
                </Widget.Row>

                { showAnswer && <>{answer}</> }
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    margin: 0 auto 20px;
    padding: 0px 30px;
    max-width: 1024px;
    width: 100%;

    @media screen and (max-width: 414px) {
        padding: 0px;
    }
`;

const QuestionText = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.click ? '#009eda' : '#dbdbdb'};
`;

export default SpFaqItem;
