import React, {Fragment} from 'react';
import { connect } from 'redux';
import ActionCretaor from '../Reducers';
import styled from 'styled-components';
import { withPage }  from '../../plugin/Page';
import SpPromotionSection from '../Components/SpPromotionSection';
import SpUseStepSection from '../Components/SpUseStepSection';
import SpTroubleshootingSection from '../Components/SpTroubleshootingSection';
import SpFaqSection from '../Components/SpFaqSection';



class StepsPage extends React.Component{

    render (){
        return (
            <Wrapper>
                <SpPromotionSection />
                <SpUseStepSection />
                <SpTroubleshootingSection />
                <SpFaqSection />
            </Wrapper>
            );
    }
}

const Wrapper = styled.div`
    background-color:#eee;
`;

export default withPage(
    StepsPage
)