import React from 'react';
import styled from 'styled-components';
import SpFaqItem from './SpFaqItem';

const AnswerText = styled.div`
    font-size: 18px;
    font-weight: 200;
    color: #dbdbdb;

    & .highlight {
        font-weight: 200;
        font-size: 18px;
        color: #009eda;
    }
`;

const FaqData = [
    {
        question: '上網卡可在哪些國家使用?',
        answer: (
          <>
            <AnswerText>
              目前我們提供<span className="highlight"><br/>A區:</span> 台灣，香港，澳門，韓國，新加坡，馬來西亞，泰國，以及
              <span className="highlight"><br/>B區:</span> 中國，日本，紐西蘭，澳洲等國家，<br/>未來將會提供更多國家及地區。
            </AnswerText>
          </>
        )
    },
    {
        question: '有哪些運送方式?',
        answer: (
          <AnswerText>
            我們提供超商取貨的服務，超商24小時營業讓您取貨時間不受限。
          </AnswerText>
        )
    },
    {
        question: '天數如何計算?',
        answer: (
          <AnswerText>
            卡片插入手機即開通，天數計算以台灣時間(GMT+8)00:00:00至23:59:59為一天，不足一天以一天計算。
          </AnswerText>
        )
    },
    {
        question: '手機型號有影響嗎?',
        answer: (
          <AnswerText>
            有支援4G的手機都可以使用，建議不要在鎖頻機，改機，山寨機上使用。
          </AnswerText>
        )
    },
    {
        question: '雙卡機可以使用嗎?',
        answer: (
          <AnswerText>
            雙卡機網卡請插SIM1卡槽，並確認網路選擇為SIM1，且避免放置其他SIM卡在SIM2卡槽。
          </AnswerText>
        )
    },
    {
        question: '我們所提供的SIM卡尺寸',
        answer: (
          <AnswerText>
            我們提供三合一SIM卡，NANO、MICRO以及NORMAL SIM，適用於大部分的智慧型手機。
          </AnswerText>
        )
    },
    {
        question: '卡片需要設定嗎?',
        answer: (
          <AnswerText>
            不需額外設定，插卡後請開啟「行動數據」與「數據漫遊」，即可開通並連接網路。
          </AnswerText>
        )
    },
    {
        question: '手機開啟漫遊功能，是否產生漫遊費用?',
        answer: (
          <AnswerText>
            我們是數據漫遊卡，在插入SIM卡後需要開啟數據漫遊功能才能使用上網服務，當中不會產生任何漫遊費用。每次使用完我們的SIM卡，或換上其他SIM卡時，請記得關閉數據漫遊功能，以免產生不必要的漫遊費用。
          </AnswerText>
        )
    },
    {
        question: '請問有支援語音通話及簡訊服務嗎?',
        answer: (
          <AnswerText>
            我們只提供流動數據服務，並不支援通話服務。
          </AnswerText>
        )
    },
    {
        question: '可以熱點分享嗎?',
        answer: (
          <AnswerText>
            可以使用手機熱點分享，但無法於電腦或WIFI機使用。且遵照公平使用原則，一般建議在2人以下使用。
          </AnswerText>
        )
    },
];

class SpFaqSection extends React.Component {
    render() {
        return (
            <Wrapper id="questions" img={`/images/sp-background/background01.jpg`}>
                <DarkContainer>
                    <Title extraCss='margin-bottom: 30px;'>常見問題</Title>
                    {
                        FaqData.map((data, i) =>
                            <SpFaqItem
                                key={data.question + i}
                                question={data.question}
                                answer={data.answer}
                            />
                        )
                    }
                </DarkContainer>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    background-image: url('${props => props.img}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
`;

const DarkContainer = styled.div`
    padding: 95px 0px;
    background-color: rgba(0, 0, 0, .8);

    @media screen and (max-width: 414px) {
        padding: 95px 20px;
    }
`;

const Title = styled.div`
    text-align: center;
    font-size: 50px;
    font-weight: 400;
    color: #dbdbdb;

    @media screen and (max-width: 1024px){
        font-size: 33px;
    }

    @media screen and (max-width: 600px){
        font-size: 20px;
    }

    ${props => props.extraCss};
`;

export default SpFaqSection;
