import React from 'react';
import styled from 'styled-components';
import * as Widget from '../../src/Components/Widget';

class SpPromotionSection extends React.Component {
    render() {
        return (
            <Wrapper img='/images/sp-photo/photo06.jpg'>
                <Mist>
                    <Widget.Row>
                        <PhotoGrid img='/images/sp-photo/photo07.jpg' />
                        <DarkGrid />
                        <TextGrid>{'支援多國\n無須換卡'}</TextGrid>
                        <DarkGrid />
                        <PhotoGrid img='/images/sp-photo/photo01.jpeg' />
                    </Widget.Row>

                    <Widget.Row>
                        <DarkGrid />
                        <TextGrid>{'獨立機房\n連線穩定'}</TextGrid>
                        <LogoGrid>
                            <img src='/images/sp-logo/global-star-logo02.png' style={{ width: 177, height: 213 }} />
                        </LogoGrid>
                        <TextGrid>{'隨插即用\n無須設定'}</TextGrid>
                        <DarkGrid />
                    </Widget.Row>

                    <Widget.Row>
                        <PhotoGrid img='/images/sp-photo/photo02.jpg' />
                        <DarkGrid />
                        <TextGrid>{'免實名制\n方便購買'}</TextGrid>
                        <DarkGrid />
                        <PhotoGrid img='/images/sp-photo/photo03.jpg' />
                    </Widget.Row>
                </Mist>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 600px){
        display: none;
    }
`;

const Mist = styled.div`
    background-color: rgba(0, 158, 218, .8);
`;

const PhotoGrid = styled.div`
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 20%;
    height: 270px;
    opacity: 0.25;

    ${props => props.css};
`;

const LogoGrid = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .28);
    width: 20%;
`;

const DarkGrid = styled.div`
    background-color: rgba(0, 0, 0, .28);
    width: 20%;
    height: 270px;
`;

const TextGrid = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 158, 218, .4);
    white-space: pre-wrap;
    letter-spacing: 2px;
    text-shadow: 1px 0 6px rgba(0, 0, 0, .12);
    font-size: 28px;
    color: #dbdbdb;
    width: 20%;
    height: 270px;

    @media screen and (max-width: 768px){
        font-size: 22px;
    }
`;

export default SpPromotionSection;
